import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import slugify from "slugify"
import $ from "jquery"
import { Icon } from "react-icons-kit"
import { asterisk } from "react-icons-kit/fa/asterisk"
import { pictureO } from "react-icons-kit/fa/pictureO"
import moment from "moment"

import AdmNavbar from "../../components/admNavbar"
import Footer from "../../components/footer"
import { VerifyUserPermission } from "../../functions/verifyUserPermision"
import { createTutorial } from "../../services/admin"
import Editor from "../../components/editor-wysiwyg"
import DatePicker from "../../components/Datepicker"
import PictManager from "../../components/pict-manager-modal"

// import "../../css/multilogica-theme.scss"

function TutorialEditor() {
  const [userVerified, setUserVerified] = useState(false)

  useEffect(() => {
    const verifyUser = async () => {
      const userHasPermission = await VerifyUserPermission("use-img-manager")
      if (!userHasPermission) {
        navigate("/403")
      } else {
        setUserVerified(true)
      }
    }
    if (!userVerified) verifyUser()
  }, [userVerified])

  const [tutorialId, setTutorialId] = useState("")
  const [title, setTitle] = useState("")
  const [picture, setPicture] = useState("")
  const [teaser, setTeaser] = useState("")
  const [body, setBody] = useState("")
  const [classification, setClassification] = useState("")
  const [publishedContent, setPublishedContent] = useState(false)
  const [publicationDate, setPublicationDate] = useState(new Date())

  const [buttonSubmitEnabled, setButtonSubmitEnabled] = useState(true)
  const [modalTutorialSaved, setModalTutorialSaved] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [modalErrorText, setModalErrorText] = useState("")
  const [jwt, setJwt] = useState("")
  const [showPictManager, setShowPictManager] = useState(false)
  const [editing, setEditing] = useState(false)
  const [errorFileds, setErrorFileds] = useState([])

  useEffect(() => {
    setJwt(localStorage.getItem("jwt"))

    const editingTutorial = JSON.parse(localStorage.getItem("editingTutorial"))
    if (editingTutorial) {
      setTutorialId(editingTutorial.id)
      setTitle(editingTutorial.title)
      setPicture(editingTutorial.picture)
      setTeaser(editingTutorial.teaser)
      setBody(editingTutorial.body)
      setClassification(editingTutorial.classification)
      setPublishedContent(editingTutorial.publishedContent)
      setPublicationDate(editingTutorial.publicationDate)
      setEditing(true)
    }
  }, [])

  const verifyData = async () => {
    let errorsAux = []
    if (title === "") {
      errorsAux.push("title")
    }
    if (picture === "") {
      errorsAux.push("picture")
    }
    if (teaser === "") {
      errorsAux.push("teaser")
    }
    if (body === "") {
      errorsAux.push("body")
    }
    if (classification === "") {
      errorsAux.push("classification")
    }
    if (!moment(publicationDate) instanceof moment) {
      errorFileds.push("publicationDate")
    }

    if (errorsAux.length === 0) {
      setButtonSubmitEnabled(false)
      const data = {
        id: editing ? tutorialId : uuidv4(),
        title: title,
        slug: slugify(title).toLowerCase(),
        picture: picture,
        teaser: teaser,
        body: body,
        classification: classification,
        publishedContent: publishedContent,
        publicationDate:
          typeof publicationDate === "object"
            ? publicationDate.toISOString()
            : publicationDate,
        permission: "create-new-tutorial",
        editing: editing,
      }
      await createTutorial(jwt, data).then(res => {
        if (res.status === 201) {
          setModalTutorialSaved(true)
        } else {
          if (res.status === 400) {
            setModalErrorText("erro 400 - Título do tutorial duplicado")
            setButtonSubmitEnabled(true)
            setModalError(true)
          } else {
            setModalErrorText("erro " + res.status + " " + res.message)
            setButtonSubmitEnabled(true)
            setModalError(true)
          }
        }
      })
    } else {
      setErrorFileds(errorsAux)
      scrollToError(errorsAux[0])
    }
  }

  const scrollToError = filedId => {
    $("html,body").animate(
      {
        scrollTop: $("#" + filedId).offset().top - 100,
      },
      "slow"
    )
  }

  const setSetTitle = e => {
    setTitle(e.target.value)
    if (e.target.value.length !== 0) {
      const index = errorFileds.indexOf("title")
      if (index > -1) {
        errorFileds.splice(index, 1)
      }
    }
  }

  const editorSetTeaser = v => {
    setTeaser(v)
    if (v !== "") {
      const index = errorFileds.indexOf("teaser")
      if (index > -1) {
        errorFileds.splice(index, 1)
      }
    }
  }

  const editorSetBody = v => {
    setBody(v)
    if (v !== "") {
      const index = errorFileds.indexOf("body")
      if (index > -1) {
        errorFileds.splice(index, 1)
      }
    }
  }

  const editorSetDate = v => {
    if (v instanceof moment) setPublicationDate(v.toDate())
  }

  const setSetClassification = e => {
    setClassification(e.target.value)
    if (e.target.value.length !== 0) {
      const index = errorFileds.indexOf("classification")
      if (index > -1) {
        errorFileds.splice(index, 1)
      }
    }
  }

  const mandatory = title => {
    return (
      <Form.Label>
        {title}
        <Icon className="mandatory" size={"10px"} icon={asterisk} />
      </Form.Label>
    )
  }

  const toggleCheck = evt => {
    if (evt.target.id === "formPublishedTutorial") {
      setPublishedContent(!publishedContent)
    }
  }

  const removeModal = () => {
    setModalTutorialSaved(false)
    localStorage.removeItem("editingTutorial")
    navigate("/admin/tutorial")
  }

  const removeModalError = () => {
    setModalError(false)
  }

  const getPictUrl = () => {
    setShowPictManager(true)
  }

  const setPictUrl = url => {
    setPicture(url)
  }

  const hidePict = e => {
    setShowPictManager(false)
  }

  return (
    <>
      <Modal show={modalTutorialSaved}>
        <Modal.Body>
          <p>O conteúdo de seu tutorial foi salvo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalError}>
        <Modal.Body>
          <p>{modalErrorText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={removeModalError}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <PictManager
        show={showPictManager}
        handleClose={hidePict}
        setImage={setPictUrl}
      />
      <AdmNavbar />
      <div className="height-40" />
      <Container>
        <Row>
          <Col>
            <h1>Tutorial</h1>
          </Col>
        </Row>
      </Container>
      {userVerified ? (
        <Container>
          <Row>
            <Col id="title">
              <Form.Group controlId="formName">
                {mandatory("Título")}
                <Form.Control
                  type="text"
                  value={title}
                  onChange={evt => setSetTitle(evt)}
                  className={errorFileds.includes("title") ? "error-field" : ""}
                />
              </Form.Group>

              <div className="custom-control custom-checkbox custom-checkbox-green">
                <input
                  onClick={evt => toggleCheck(evt)}
                  onChange={evt => toggleCheck(evt)}
                  type="checkbox"
                  className="custom-control-input custom-control-input-green"
                  id="formPublishedTutorial"
                  checked={publishedContent ? true : false}
                />
                <label
                  className="custom-control-label"
                  htmlFor="formPublishedTutorial"
                >
                  Publicado
                </label>
              </div>
              <DatePicker
                onChangeDate={editorSetDate}
                initialDate={publicationDate}
              />
              <div style={{ height: "20px" }} id="picture"/>
              <Form.Group controlId="formMessage">
                {mandatory("Imagem")}
                <Button
                  style={{ marginLeft: "8px" }}
                  onClick={getPictUrl}
                  className={
                    errorFileds.includes("picture") ? "error-field" : ""
                  }
                >
                  <Icon size={"24px"} icon={pictureO} />
                </Button>
              </Form.Group>
              {picture !== "" ? (
                <div className="content-icon">
                  <img src={picture} alt="content-icon" />
                </div>
              ) : (
                <></>
              )}
              <Form.Group controlId="formMessage" id="teaser">
                {mandatory("Teaser")}
                <div
                  className={
                    errorFileds.includes("teaser") ? "error-field" : ""
                  }
                >
                  <Editor onChangeEditor={editorSetTeaser} value={teaser} />
                </div>
              </Form.Group>
              <div style={{ height: "20px" }} id="body"/>
              <Form.Group
                controlId="formMessage"
                className={errorFileds.includes("body") ? "error-field" : ""}
              >
                {mandatory("Conteúdo")}

                <Editor onChangeEditor={editorSetBody} value={body} />
              </Form.Group>
              <div style={{ height: "20px" }} id="classification"/>
              <Form.Group controlId="formClassification">
                {mandatory("Classificação")}
                <Form.Control
                  type="text"
                  value={classification}
                  onChange={evt => setSetClassification(evt)}
                  className={
                    errorFileds.includes("classification") ? "error-field" : ""
                  }
                />
              </Form.Group>
              <div style={{ height: "20px" }} />
              <Button
                onClick={evt => verifyData()}
                disabled={!buttonSubmitEnabled}
                className="payment-button"
              >
                Salvar
              </Button>
              <div
                style={{
                  display: !buttonSubmitEnabled ? "block" : "none",
                  float: "left",
                  margin: "4px 0 0 6px",
                }}
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col>
              <Spinner animation="border" size="sm" variant="primary" />
            </Col>
          </Row>
        </Container>
      )}

      <div className="height-40" />
      <Footer />
    </>
  )
}

export default TutorialEditor
